<template>
  <div id="left-sidebar" class="sidebar">
    <div class="">
      <div class="navbar-brand">
        <router-link
          class="main-logo"
          to="/home"
          v-if="toggleSidebarTheme == ''"
          ><img
            src="@/assets/payangel_logo.png"
            alt="PayAngel Logo"
            class="img-responsive logo"
        /></router-link>
        <router-link
          class="main-logo-icon"
          to="/home"
          v-if="toggleSidebarTheme == ''"
          ><img
            src="@/assets/logo-icon.svg"
            alt="PayAngel Logo"
            class="img-responsive logo img-fluid"
        /></router-link>

        <router-link
          class="main-logo"
          to="/home"
          v-if="toggleSidebarTheme == 'full-dark' && isSidebarSmall == false"
          ><img
            src="@/assets/payangel_logo_w.png"
            alt="PayAngel Logo"
            class="img-responsive logo"
        /></router-link>
        <router-link
          class="main-logo-icon"
          to="/home"
          v-if="toggleSidebarTheme == 'full-dark' && isSidebarSmall == true"
          ><img
            src="@/assets/logo-icon-w.svg"
            alt="PayAngel Logo"
            class="img-responsive logo img-fluid"
        /></router-link>

        <button
          type="button"
          class="btn-toggle-offcanvas btn btn-sm btn-default"
          v-on:click="backSideMenu()"
        >
          <i class="lnr lnr-menu fa fa-chevron-circle-left"></i>
        </button>
      </div>
      <div class="user-account">
        <div class="user_div">
          <img
            :src="require('@/assets/img/favicon.png')"
            class="rounded-circle user-photo"
            alt="User Profile Picture"
          />
        </div>
        <div
          class="dropdown"
          v-bind:class="{ show: dropActive }"
          @click="dropActive = !dropActive"
          v-click-outside="outside"
        >
          <a
            href="javascript:void(0);"
            class="dropdown-toggle user-name"
            data-toggle="dropdown"
            :aria-expanded="dropActive ? 'true' : 'false'"
            ><strong>{{ username }}</strong></a
          >
          <ul
            class="dropdown-menu dropdown-menu-right account"
            v-bind:class="{ show: dropActive }"
            :style="
              dropActive
                ? 'top: 100%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'
                : 'top: 80%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'
            "
          >
            <!-- <li><router-link to="/admin/setting/profile"><i class="icon-user"></i>My Profile</router-link></li>
              <li class="divider"></li> -->
              <li><router-link to="/authentication/page-login"><i class="icon-power"></i>Logout</router-link></li>
          </ul>
        </div>
      </div> 
      <!-- Nav tabs -->
      <ul class="nav nav-tabs">
        <li class="nav-item"><a class="nav-link" v-on:click="setActiveNavtab('Menu')" :class="{ 'active show': openNavtab('Menu')}" data-toggle="tab">Menu</a></li>
        <!-- <li class="nav-item"><a class="nav-link" v-on:click="setActiveNavtab('Setting')" :class="{ 'active show': openNavtab('Setting')}" data-toggle="tab"><i class="icon-settings"></i></a></li> -->
      </ul>  
      <!-- Tab panes -->
      <div class="tab-content p-l-0 p-r-0">
          <div class="tab-pane" :class="{ 'active show': openNavtab('Menu')}" id="menu">
            <nav id="left-sidebar-nav" class="sidebar-nav">
              <ul id="main-menu" class="metismenu">
                <li v-bind:class="{ 'active': currentActiveMenu == 'dashboard' }" @click="setActive('dashboard')">
                    <router-link :to="{ name: 'dashboard'}"><i class="icon-home"></i><span>Dashboard</span></router-link>
                </li>
                <li v-bind:class="{ 'active': currentActiveMenu == 'pay-transfer' }" @click="setActive('pay-transfer')">
                    <router-link to="/admin/pay-transfer/pay-transfer-details" title="Pay or Transfer"><i class="fa fa-money"></i> <span>Pay or Transfer</span></router-link>
                </li>
                <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'send-money' }" @click="setActive('send-money')">
                    <router-link :to="{ name: 'send-money'}"><i class="fa fa-money"></i><span>Send Money</span></router-link>
                </li>  -->
                <li v-bind:class="{ 'active': currentActiveMenu == 'transaction' }" @click="setActive('transaction')">
                    <router-link to="/admin/transaction/list" title="Transaction List"><i class="fa fa-bars"></i><span>Transaction List</span></router-link>
                </li>
                <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'reports' }" @click="setActive('reports')">
                    <router-link to="/admin/reports" title="Reports"><i class="fa fa-list"></i><span>Reports</span></router-link>
                </li>-->
                <li v-bind:class="{ 'active': currentActiveMenu == 'payment-link' }" @click="setActive('payment-link')">
                    <router-link to="/admin/payment-link/list" title="Payment Link"><i class="fa fa-bars"></i><span>Payment Link</span></router-link>
                </li>
                <li :class="{ 'active': currentActiveMenu === 'setting'}">
                    <a class="has-arrow" v-on:click="setActive('setting')"><i class="fa fa-cog"></i> <span>Settings</span></a>
                    <ul v-bind:class="(currentActiveMenu == 'setting') && isColapse ? 'collapse in' : 'collapse'">
                        <!-- <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/user-list' || currentRoutePath == '/admin/setting/user-add'}" @click="isClick()"><router-link to="/admin/setting/user-list">User Management</router-link></li> -->
                        <!-- <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/profile'}" @click="isClick()"><router-link to="/admin/setting/profile">My Profile</router-link></li> -->
                        <!-- <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/setup-profile'}" @click="isClick()"><router-link to="/admin/setting/setup-profile">Set Up Your Profile</router-link></li> -->
                        <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/channels'}" @click="isClick()"><router-link to="/admin/setting/channels">Channels</router-link></li>
                        <!-- <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/blank-channels'}" @click="isClick()"><router-link to="/admin/setting/blank-channels">Channels (Blank)</router-link></li> 
                        <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/preferences'}" @click="isClick()"><router-link to="/admin/setting/preferences">My Preferences</router-link></li>
                        <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/limits'}" @click="isClick()"><router-link to="/admin/setting/limits">My Limits</router-link></li>
                        <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/payouts'}" @click="isClick()"><router-link to="/admin/setting/payouts">Payouts</router-link></li>
                        <li v-bind:class="{ 'active':currentRoutePath == '/admin/setting/compliance'}" @click="isClick()"><router-link to="/admin/setting/compliance">Compliance</router-link></li> -->
                    </ul>
                </li> 

                <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'report-bug' }">
                            <router-link to="/admin/report-bug"><i class="fa fa-file"></i> <span>Report Bug</span></router-link>
                        </li> -->
                <!-- <li v-bind:class="{ 'active': currentActiveMenu == 'my-wallet' }">
                            <router-link :to="{ name: 'my-wallets'}"><i class="icon-wallet"></i><span>My Wallet</span></router-link>
                        </li> -->
              
                <li v-bind:class="{ 'active': currentActiveMenu == 'fund' }" @click="setActive('fund')">
                  <router-link to="/admin/fund" title="Fund"><i class="fa fa-money"></i> <span>Fund</span></router-link>
                </li>
                <li
                  v-bind:class="{ active: currentActiveMenu == 'user' }"
                  @click="setActive('user')"
                  v-if="role != 'employee'"
                >
                  <!-- admin/setting/user-list -->
                  <router-link to="/admin/user/list" title="User List"
                    ><i class="fa fa-users"></i
                    ><span>User List</span></router-link
                  >
                </li>
              </ul>
            </nav>
          </div>
        <!-- <div class="tab-pane p-l-15 p-r-15" :class="{ 'active show': openNavtab('Setting')}" id="setting">
                <h6>Choose Mode</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="white" :class="{ 'active': data_theme === ''}"  v-on:click="setactiveTheme('')">
                        <div class="white"></div>
                        <span>Light</span>
                    </li>
                    <li data-theme="black" :class="{ 'active': data_theme === 'full-dark'}"  v-on:click="setactiveTheme('full-dark')">
                        <div class="black"></div>
                        <span>Dark</span>
                    </li>
                </ul>
                <hr>
                <h6>Choose Skin</h6>
                <ul class="choose-skin list-unstyled">
                    <li data-theme="radical-red"  v-bind:class="{ 'active': activeColor === 'radical-red'}" v-on:click="changeBodycolor('radical-red')">
                        <div class="radical-red"></div>
                        <span>Radical Red</span>
                    </li>                   
                    <li data-theme="turquoise"  v-bind:class="{ 'active': activeColor === 'turquoise'}" v-on:click="changeBodycolor('turquoise')">
                        <div class="turquoise"></div>
                        <span>Turquoise</span>
                    </li>
                    <li data-theme="midnight-express" v-bind:class="{ 'active': activeColor === 'midnight-express'}" v-on:click="changeBodycolor('midnight-express')">
                        <div class="midnight-express"></div>
                        <span>Midnight Express</span>
                    </li>
                    <li data-theme="morning-glory"  v-bind:class="{ 'active': activeColor === 'morning-glory'}" v-on:click="changeBodycolor('morning-glory')">
                        <div class="morning-glory"></div>
                        <span>Morning Glory</span>
                    </li>
                    <li data-theme="dark-cyan"  v-bind:class="{ 'active': activeColor === 'dark-cyan'}" v-on:click="changeBodycolor('dark-cyan')">
                        <div class="dark-cyan"></div>
                        <span>Dark Cyan</span>
                    </li>
                </ul>
            </div>              -->
      </div>
    </div>
  </div>
</template>
<script>
//import { EventBus } from '@/plugins/event-bus.js';
export default {
  name: "SidebarComponent",
  methods: {
    changeBodycolor(color) {
      this.activeColor = color;
      document.body.className = "theme-" + color + " " + this.data_theme;
    },

    outside: function () {
      this.dropActive = false;
    },

    setActive: function (menuItem) {
      if (this.currentActiveMenu === menuItem && this.isColapse) {
        this.currentActiveMenu = null;
        this.isColapse = false;
      } else {
        this.currentActiveMenu = menuItem;
        this.isColapse = true;
      }
    },

    openNavtab: function (tab) {
      return this.currentMenuTab === tab;
    },
    setActiveNavtab: function (tab) {
      this.currentMenuTab = tab;
    },
    setactiveTheme: function (theme) {
      this.data_theme = theme;
      document.body.className = "theme-" + this.activeColor + " " + theme;
      //EventBus.$emit('theme',theme)
    },
    backSideMenu: function () {
      document.body.classList.toggle("offcanvas-active");
    },
    isClick() {
      if (sessionStorage.getItem("sidebarmini") == "true") {
        this.isColapse = !this.isColapse;
      }
    },
  },
  data() {
    return {
      isSidebarSmall: false,
      currentActiveMenu: null,
      //currentActiveMenu:  this.$route.matched[0].path.replace(/\//,''),
      //currentActiveMenu: window.location.pathname.split("/")[1],
      currentMenuTab: "Menu",
      activeColor: "turquoise",
      dropActive: "",
      data_theme: "",
      toggleSidebarClass: false,
      toggleSidebarTheme: "",
      isColapse: false,

      username: "",
      role: "",
    };
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler);
      },
    },
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },

    activeMenu() {
      if (this.$route.matched.length > 0) {
        var menuList = this.$route.matched[0].path.split("/");
        var index = menuList.indexOf("admin");
        if (index > -1) {
          menuList.splice(index, 1);
        }
        var matchedMenu = menuList.filter((item) => item);
        return matchedMenu[0];
      }
      return false;
    },
  },
  watch: {
    $route: {
      immediate: true, // also trigger handler on initial value
      handler() {
        if(this.$route && this.$route.name){
          this.setActive(this.$route.name)
          // console.log(this.$route.name);
        }
      }
    },
    // $route (){
    //     console.log("matched > > >",this.matched);
    //     //this.currentActiveMenu = this.activeMenu.replace(/\//,'');
    //     document.body.className = 'theme-'+this.activeColor +' '+ this.data_theme;
    //     if(sessionStorage.getItem("sidebarmini") == 'true'){
    //         document.body.classList.add("layout-fullwidth")
    //     }
    // }
  },
  mounted() {
    if (sessionStorage.getItem("sidebarmini") == "true") {
      document.body.classList.add("layout-fullwidth");
    }
    // EventBus.$on('theme', (theme) => {
    //     this.toggleSidebarTheme = theme
    // })
    // EventBus.$on('sidebar-mini', (change) => {
    //     this.isSidebarSmall = change;
    //     this.currentMenuTab = 'Menu'
    // })
    setTimeout(() => {
      if (this.$route.path.includes("setting")) {
        this.setActive("setting");
      } else {
        this.setActive(this.$route.name);
      }
    }, 100);
    this.username = sessionStorage.getItem("userName");
    this.role = sessionStorage.getItem("rolename");
    if(this.$route && this.$route.name){
      this.setActive(this.$route.name);
      // console.log(this.$route.name);
    }
  },
};
</script>
